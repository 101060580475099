import "date-fns";
import React from "react";
import { Typography, Grid, Link, IconButton } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

const UserInfo = props => {
  const { MyTextField } = props;
  const today = new Date();
  const tomorrow = new Date();
  const minDate = tomorrow.setDate(today.getDate() + 2);


  return (
    <React.Fragment>
      <Grid container spacing={3} justify="center">
        <Grid item xs={12}>
          <Typography variant="h6">Ihre Angaben</Typography>
        </Grid>
        <Grid item xs={12} lg={4}>
          <MyTextField
            type="text"
            name="vorname"
            label="Vorname"
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <MyTextField
            type="text"
            name="nachname"
            label="Nachname"
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <MyTextField
            className="mb-16"
            type="text"
            name="email"
            label="Email Adresse"
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <MyTextField
            type="text"
            name="telefonnummer"
            label="Telefonnummer"
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <MyTextField
            type="text"
            name="adresse"
            label="Adresse"
            variant="outlined"
            required
          />
        </Grid>
        <br />
        <br />
        <Grid item xs={12} lg={8}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              label="Gewünschtes Lieferdatum"
              value={minDate}
              minDate={minDate}
              onChange={e => props.setFieldValue("date", e)}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
              shouldDisableDate={(date) => {
                return date.getDay() === 0 || 
                      date.getDay() === 1 || 
                      date.getDay() === 3 ||
                      date.getDay() === 4 || 
                      date.getDay() === 6;
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <br />
        <Grid item xs={12} lg={8}>
          <MyTextField
            type="text"
            name="bemerkungen"
            label="Bemerkungen"
            variant="outlined"
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <MyTextField
            type="checkbox"
            name="check2"
            label={`* Erklären Sie sich mit unseren AGBs einverstanden?`}
            required
          />
          <IconButton color="inherit" aria-label="Open drawer" size="small">
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://24999861-588507224323046011.preview.editmysite.com/uploads/2/4/9/9/24999861/boarbichlonlineshopagbs.pdf"
            >
              AGBs
            </Link>
          </IconButton>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default UserInfo;
