import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Link,
  Button,
  StepLabel,
  Step,
  Stepper,
  Paper,
  Toolbar,
  AppBar,
  CssBaseline,
  Checkbox,
  TextField,
  Grid,
  LinearProgress,
  FormControlLabel
} from "@material-ui/core";
import { Formik, useField, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import config from "./config";
import Products from "./components/Products";
import UserInfo from "./components/UserInfo";
import Review from "./components/Review";
import ThankYou from "./components/ThankYou";

const validationSchema = Yup.object().shape({
  vorname: Yup.string().required("Pflichtfeld"),
  nachname: Yup.string().required("Pflichtfeld"),
  email: Yup.string()
    .email()
    .required("Pflichtfeld"),
  telefonnummer: Yup.string().required("Pflichtfeld"),
  adresse: Yup.string().required("Pflichtfeld"),
  date: Yup.string().required("Pflichtfeld"),
  check2: Yup.bool().oneOf([true], "Um zu bestellen müssen unsere AGBs akzeptiert werden.")
});

const Copyright = () => {
  return (
    [
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.boarbichl.com/">
        Hofkäserei Boarbichl
      </Link>{" "}
      {new Date().getFullYear()}
      {""}
    </Typography>,
    <Typography variant="body2" color="textSecondary" align="center" paragraph="true">
    <Link color="inherit" target="_blank" href="https://24999861-588507224323046011.preview.editmysite.com/uploads/2/4/9/9/24999861/boarbichlonlineshopagbs.pdf">
      AGBs
    </Link>{" - "}
    <Link color="inherit" target="_blank" href="https://www.boarbichl.com/impressum-und-datenschutz.html">
      Impressum
    </Link>{" "}
    </Typography>
    ]
);
};

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "sticky"
  },
  toolBar: {
    flexDirection: "column",
    alignItems: "flex-start"
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
      width: 800,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  },
  stepper: {
    padding: theme.spacing(3, 0, 5)
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  totalPrice: {
    justifyContent: "flex-end",
    flexDirection: "column"
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  price: {
    marginTop: theme.spacing(3),
    justifyContent: "flex-end",
    display: "flex"
  }
}));

const steps = ["Auswahl", "Lieferdaten", "Checkout"];

export default () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [price, setPrice] = React.useState({});
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");

  const MyTextField = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    switch (props.type) {
      case "text":
      case "date":
        return (
          <>
            <TextField
              label={label}
              {...field}
              {...props}
              error={meta.error}
              fullWidth
            />{" "}
            <br />
            {meta.touched && meta.error ? (
              <Typography variant="caption" color="error">
                {meta.error}
              </Typography>
            ) : null}
          </>
        );
      case "checkbox":
        return (
          <>
            <FormControlLabel
              control={<Checkbox {...field} {...props} />}
              label={label}
            />
            {meta.touched && meta.error ? (
              <Typography variant="caption" color="error">
                {meta.error}
              </Typography>
            ) : null}
          </>
        );
      default:
        return null;
    }
  };

  const renderStep = (_activeStep, form) => {
    switch (_activeStep) {
      case 0:
        return <Products price={price} setPrice={setPrice} />;
      case 1:
        return <UserInfo {...form} MyTextField={MyTextField} />;
      case 2:
        return <Review price={price} total={calculateTotal(price)} />;
      default:
        throw new Error("Unknown step");
    }
  };

  const calculateTotal = object => {
    return Object.keys(object)
      .reduce((acc, value) => acc + object[value]["price"], 0)
      .toFixed(2);
  };

  const validateNext = () => {
    return !(calculateTotal(price) > 18);
  };

  const submit = async (values, { setSubmitting }) => {
    const { check1, check2, ...otherValues } = values;
    const payload = { ...otherValues, order: price };
    setSuccess("");
    setError("");
    try {
      await axios.post(`${config.apiBaseUrl}/api/order`, payload);
      setSubmitting(false);
      setSuccess("Order successfully submitted!");
      setActiveStep(3);
    } catch (error) {
      setError("Da lief was schief... Bitte sende Deine Bestellung an boarbichl@schenna.com");
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="sticky" style={{ background: '#F5B041' }}  className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <Typography variant="h6" color="inherit" noWrap>
            <b>Boarbichl Lieferservice</b>
          </Typography>
          <Typography variant="body1" color="inherit" noWrap>
          <span>&#128722;</span> Gesamtbestellwert: <b>{calculateTotal(price)}€</b>
          </Typography>
        </Toolbar>
      </AppBar>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          {activeStep !== 3 ? (
            <React.Fragment>
             <Stepper activeStep={activeStep} className={classes.stepper}>
                {steps.map(label => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Formik
                initialValues={{
                  vorname: "",
                  nachname: "",
                  email: "",
                  telefonnummer: "",
                  nameIhrerUnterkunft: "",
                  adresse: "",
                  date: new Date(),
                  check1: false,
                  check2: false,
                  bemerkungen: ""
                }}
                validationSchema={validationSchema}
                onSubmit={submit}
              >
                {form => {
                  return (
                    <Form>
                      {renderStep(activeStep, { ...form })}
                      <Grid container spacing={3}>
                        {form.isSubmitting && (
                          <Grid item xs={12}>
                            <LinearProgress />
                          </Grid>
                        )}
                        {error && (
                          <Typography variant="h6" color="error">
                            {error}
                          </Typography>
                        )}
                        {success && (
                          <Typography variant="h6" color="secondary">
                            {success}
                          </Typography>
                        )}
                        <Grid item xs={12}>
                          <div className={classes.totalPrice}>
                            {activeStep !== 2 && (
                              <Typography
                                variant="h6"
                                color="inherit"
                                className={classes.price}
                              >
                                Gesamtbestellwert:&nbsp;
                                <b>{calculateTotal(price)}€</b>
                              </Typography>
                            )}
                          </div>

                          {activeStep < steps.length && (
                            <div className={classes.buttons}>
                              <Button
                                type="button"
                                variant="outlined"
                                disabled={activeStep <= 0}
                                onClick={() => setActiveStep(activeStep - 1)}
                                className={classes.button}
                              >
                                Zurück
                              </Button>
                              {activeStep < steps.length - 1 && (
                                <Button
                                  type="button"
                                  variant="contained"
                                  color="secondary"
                                  disabled={validateNext()}
                                  className={classes.button}
                                  onClick={() => {
                                    if (activeStep === 0) {
                                      return setActiveStep(activeStep + 1);
                                    }
                                    form.validateForm().then(res => {
                                      if (Object.keys(res).length === 0) {
                                        return setActiveStep(activeStep + 1);
                                      }
                                      form.setTouched(res);
                                    });
                                  }}
                                >
                                  Weiter
                                </Button>
                              )}
                              {activeStep >= steps.length - 1 && (
                                <Button
                                  disabled={form.isSubmitting}
                                  variant="contained"
                                  color="primary"
                                  type="button"
                                  className={classes.button}
                                  onClick={e => {
                                    e.persist(); // Otherwise React complains.
                                    form.validateForm().then(res => {
                                      if (Object.keys(res).length === 0) {
                                        return form.handleSubmit(e);
                                      }

                                      form.setTouched(res);
                                    });
                                  }}
                                >
                                  Abschicken
                                </Button>
                              )}
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </React.Fragment>
          ) : (
            <ThankYou />
          )}
        </Paper>
        <Copyright />
      </main>
    </React.Fragment>
  );
};
